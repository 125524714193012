import Vue from "vue";
import Vuex from "vuex";
import snackbar from './modules/snackbar'
import login from './modules/login'
import prospeccao from './modules/prospeccao'
import cidades from './modules/cidades'
import cep from './modules/cep'
import contatos from './modules/contatos'
import segmentacao from './modules/segmentacao'
import origem from './modules/origem'
import competidor from './modules/competidor'
import agendamento from './modules/agendamento'
import usuario from './modules/usuario'
import diagnostico from './modules/diagnostico'
import negociacao from './modules/negociacao'
import utils from './modules/utils'
import filter from './modules/filter'
import index from './modules/index'
import estrelas from "./modules/estrelas";
import historico from "./modules/historico";
import viewmaps from "./modules/viewmaps";
import funil from "./modules/funil";
import etapa from "./modules/etapa";
import report from "./modules/report";
import webSocket from "./modules/webSocket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    isLoggedIn: state => !!state.token,
  },
  mutations: {},
  actions: {},
  modules: { 
    snackbar, 
    login, 
    prospeccao, 
    cidades, 
    cep, 
    contatos, 
    segmentacao, 
    origem, 
    competidor, 
    agendamento, 
    usuario, 
    diagnostico, 
    negociacao, 
    utils, 
    filter,
    index,
    estrelas,
    viewmaps,
    historico,
    funil,
    etapa,
    report,
    webSocket
  },
});
